<template>
  <v-app-bar
    color="blue accent-4"
    dark
    app 
    fixed
    height="80"
    max-height="160"
    src=""
  >
    <img alt="Convert JSON to YAML Online" 
    style="height: 40px;margin: 0 0 0 -9vu;"
    src="../assets/logo-no-background.png"/>
    <h1>&nbsp;Convert JSON to YAML Online</h1>
    <!-- <div style="margin-left: auto; margin-right: 0;">
      <v-btn small class="ma-2" icon  @click="openFeedbackForm">
        <v-icon>mdi-message-draw</v-icon> 
      </v-btn>
      <Feedback />
    </div> -->
  </v-app-bar>
</template>
<script>
// import FeedbackForm from "@/components/FeedbackForm.vue";
 
export default{
  name : "AppHeader"
//   components: {
//     FeedbackForm,
//   },
//   methods: {
//     openFeedbackForm() {
//       this.$refs.feedback.openFeedbackForm();
//     },
//   },
}
</script>
<style scoped>
 .v-application .blue.accent-4 {
  background-color: #3883fa !important;
  border-color: #3883fa !important;
}
</style>
