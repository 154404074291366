<template>
    <v-footer
      color="blue accent-4"
      light
      height="40"
      class="footer-position"
      max-height="40"
      src=""
    >
      <v-spacer></v-spacer>
      <small>Copyright © JSON to YAML Online {{currentYear}}</small>
      <v-spacer></v-spacer>      
    </v-footer>
</template>
<script>

export default{
    name : "AppFooter",
    data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
}
</script>
<style scoped>
   .v-application .blue.accent-4 {
    background-color: #3883fa !important;
    border-color: #3883fa !important;
}
.footer-position{
  width: 100%;
  bottom: -12px;
 }
</style>