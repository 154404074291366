<template>
    <v-container style="height: 100%;">
        <div class="jsoneditor-container" :class="{'max-box':max,'min-box':!max}" :style="getHeight"   no-resize filled rows="28">
        <div ref="jsoneditor" class="jsoneditor-box" ></div>        
    </div>
    </v-container>
</template>
<script>
import JSONEditor from "jsoneditor/dist/jsoneditor.min.js"
import 'jsoneditor/dist/jsoneditor.min.css'

export default{
    name : "JsonEditor",
   // props:['error'],
    props: {
    options: {
      type: Object,
      default: () => {
        return {mode :"code"}
      }
    },
    value: [Object, Array, Number, String, Boolean],
    height: {
      type: String
    },
    initVal : {},
    plus: {
      type: Boolean,
      default: true
    }
    },
    data(){
        return{
            inputjson : null,
            editor : null,
            style: {},
            max: false,
            internalChange: false
        }
    },
    methods:{
      setData(data){
        this.editor.set(data)
      },
        senddata() {
              this.$emit('getdata', this.inputjson);
           },
           clear(){
            this.editor.set()
           },
           reset(){
            this.inputjson = null
           },
        onChange() {
            let error = null
            let json = {}
            try {
              json = this.editor.get()
            } catch (err) {
              error = err
            }
            if (error) {
              this.$emit("error", error)
            } 
            else {
              if (this.editor) {
                this.internalChange = true
                this.$emit("input", json)
                this.$nextTick(() => {
                  this.internalChange = false
                })
              }
            }         
        },
        initView() {
            if (!this.editor) {
              var container = this.$refs.jsoneditor
              let cacheChange = this.options.onChange
              delete this.options.onChange
              const options = Object.assign(this.options, {
                onChange: this.onChange
              })
              this.editor = new JSONEditor(container, options)
              this.options.onChange = cacheChange
              
            }
            this.editor.set(this.initVal)          
        },
        destroyView() {
          if (this.editor) {
            this.editor.destroy()
            this.editor = null
          }
        }
    },
    watch: {
      value: {
        handler(value) {
          if (this.editor && value !== undefined && !this.internalChange) {
            this.editor.set(value)
          }
        },
        deep: true
      },
      max(value) {
        this.$nextTick(() => {
          this.initView()
        })
      },
      options: {
        handler (value) {
          if (this.options && this.options.mode && this.editor) {
            this.editor.setMode(this.options.mode)
          }
        },
        deep: true
      }
    },
    mounted() {
      this.initView()
    },
    beforeDestroy() {
      this.destroyView()
    },
    computed: {
      getHeight() {
        if (this.height && !this.max) {
          return {
            height: this.height
          }
        }
        return {}
      }
    }
}
</script>
<style lang="css" >
.code-textarea {
  font-family: 'Courier New', monospace;;
}
.jsoneditor-container.max-box {
  /* position: fixed; */
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.ace_scrollbar{
  z-index: 0 !important;
}
.jsoneditor-container.min-box {
  position: relative;;
  min-width: 300px;
  height: 100%;
  width: 100%;
}

.jsoneditor-box {
  height: 100%;
}

.jsoneditor-container:hover .max-btn {
  display: block;
}
.max-btn:hover {
  border: 1px solid #d7e6fe;
}
::v-deep .jsoneditor .jsoneditor-text-errors tr.parse-error {
    background-color: rgba(255,255,255,1) !important;
}
</style>