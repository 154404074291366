<template>
     <v-container>
      <v-row>
        <v-col>
          <v-toolbar
          height="40"
          color="#3883fa"
        class ="rounded-lg rounded-b-0"
  elevation="2"
><v-toolbar-title
style="color:white" 
>{{ format }}</v-toolbar-title>
<v-spacer></v-spacer>
 <!-- <v-btn small class="ma-2" icon>
       <v-icon>mdi-magnify</v-icon>
      </v-btn> -->
      <v-btn small class="ma-2" icon  @click="copy">
        <v-icon color="white">mdi-content-copy</v-icon>
      </v-btn>
      <!-- <v-btn small class="ma-2" icon @click="reset">
        <v-icon>mdi-reload</v-icon>
      </v-btn> -->
      
</v-toolbar>
</v-col>
      </v-row>
        <v-row no-gutters>
        <v-col>
          <prism-editor 
          class="my-editor" 
          v-model="feed" 
          :highlight="highlighter" 
          line-numbers></prism-editor>
        <!-- <v-textarea
          filled
          dense
          rows="27"
          :value="feed"
          no-resize
          spellcheck="false"
          class="code-textarea"         
        ></v-textarea> -->
      </v-col>      
</v-row>
    </v-container>
</template>
<script>
  import { PrismEditor } from 'vue-prism-editor';
  import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  import 'prismjs/themes/prism-tomorrow.css';
  import 'prismjs/themes/prism-tomorrow.min.css';

export default{
    name : "OutputField",      
    props:['feed', 'format'],
    components : {
      PrismEditor
    },
    methods: {
      highlighter(code) {
        return highlight(code, languages.js); // languages.<insert language> to return html with markup
      },
      copy() {        
        let val = document.getElementById("outputtextarea").value        
        navigator.clipboard.writeText(val)
        // .then(() => {
        //   alert("Copied!")                           
        //                 })
                        .catch(err => {
                            console.log(err);
                        });
                      
        }
      }  
}
</script>
<style >
.code-textarea {
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'Source Code Pro', 'source-code-pro', monospace !important;
  background: rgb(255, 255, 255);
  height: 100%;
  
}
.code-textarea textarea{
  line-height: 1.3rem !important;
  font-size: 1rem;
}
.my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    border: #3883fa solid 2px;
    background-color: #2a2828;
    border-radius: 0px 0px 9px 9px;
    color: #ffffff;
    font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'Source Code Pro', 'source-code-pro', monospace !important;
    font-size: 14px;
    line-height: 1.2rem;
    padding: 5px;
    height: 595px;
  }

.jsoneditor-menu {
    width: 100%;
    padding: 2px;
    margin: 0;
    box-sizing: border-box;
    color: #fff;
    background-color: #3883fa;
    border-bottom: 1px solid #3883fa;
    border-radius: 5px 0 !important;
}

</style>