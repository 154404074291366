var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"color":"black"}},[_c('div',[_c('v-card')],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elevation-9 rounded-lg mx-5 pa-5 my-3"},[_c('h1',[_vm._v("What is json ?")]),_c('p',[_c('a',{attrs:{"href":"https://www.json.org/json-en.html"}},[_vm._v(" JSON (JavaScript Object Notation)")]),_vm._v(" is a lightweight data-interchange format. It is easy for humans to read and write. It is easy for machines to parse and generate. It is based on a subset of the JavaScript Programming Language Standard ECMA-262 3rd Edition - December 1999. JSON is a text format that is completely language independent but uses conventions that are familiar to programmers of the C-family of languages, including C, C++, C#, Java, JavaScript, Perl, Python, and many others. These properties make JSON an ideal data-interchange language. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elevation-9 rounded-lg mx-5 pa-5 my-3"},[_c('h1',[_vm._v("What is YAML ?")]),_c('p',[_c('a',{attrs:{"href":"https://yaml.org/"}},[_vm._v("YAML")]),_vm._v(" is a human-friendly data serialization language for all programming languages. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elevation-9 rounded-lg mx-5 pa-5 my-3"},[_c('h1',[_vm._v("What is XML ?")]),_c('p',[_c('a',{attrs:{"href":"https://en.wikipedia.org/wiki/XML"}},[_vm._v("XML")]),_vm._v(" Extensible Markup Language (XML) is a markup language and file format for storing, transmitting, and reconstructing arbitrary data. It defines a set of rules for encoding documents in a format that is both human-readable and machine-readable. The World Wide Web Consortium's XML 1.0 Specification[2] of 1998[3] and several other related specifications[4]—all of them free open standards—define XML. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elevation-9 rounded-lg mx-5 pa-5 my-3"},[_c('h1',[_vm._v("Introduction:")]),_c('p',[_vm._v("Welcome to the ultimate JSON to YAML converter tool! If you're working with data in JSON format and need to convert it to YAML for your projects, you're in the right place. Our online converter makes the process quick and hassle-free, eliminating the need for downloads or installations. Whether you're a developer, data analyst, or simply someone who needs to switch between these two data formats, our tool is here to simplify the process. ")]),_c('h2',[_vm._v("Why Convert JSON to YAML?")]),_c('p',[_vm._v("JSON (JavaScript Object Notation) and YAML (YAML Ain't Markup Language) are both popular data interchange formats, each with its unique strengths. While JSON is widely used for its simplicity and compatibility with JavaScript, YAML is preferred for its human-readable, structured format. Converting JSON to YAML can be necessary for various reasons")]),_c('h2',[_vm._v("Configuration Files:")]),_c('p',[_vm._v("Many software applications and systems use YAML for configuration files, making it essential to convert JSON data into YAML to configure these systems correctly.")]),_c('h2',[_vm._v("Documentation:")]),_c('p',[_vm._v("YAML is often used in documentation files due to its readability. Converting JSON to YAML makes it more accessible to users.")]),_c('h2',[_vm._v("Data Transformation:")]),_c('p',[_vm._v("When you need to transform data into a more human-friendly format, YAML is a great choice. Converting JSON to YAML is ideal for such cases.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elevation-9 rounded-lg mx-5 pa-5 my-3"},[_c('h1',[_vm._v("Features of Our JSON to YAML Converter:")]),_c('h2',[_vm._v("Online and Free:")]),_c('p',[_vm._v("Our tool is entirely web-based, and you can use it for free without any hidden costs.")]),_c('h2',[_vm._v("User-Friendly:")]),_c('p',[_vm._v("You don't need to be a tech guru to use our converter. It's designed with a user-friendly interface that's intuitive and easy to navigate.")]),_c('h2',[_vm._v("No Installation Required:")]),_c('p',[_vm._v("Forget about downloading and installing software. You can perform the conversion directly from your web browser.")]),_c('h2',[_vm._v("Secure and Private:")]),_c('p',[_vm._v("Your data is important to us. We ensure the privacy and security of your data during the conversion process.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elevation-9 rounded-lg mx-5 pa-5 my-3"},[_c('h1',[_vm._v("How to Convert JSON to YAML? ")]),_c('h2',[_vm._v("Upload JSON: ")]),_c('p',[_vm._v("Click on the \"Upload JSON File\" button or paste your JSON data directly into the provided text box.")]),_c('h2',[_vm._v("Convert:")]),_c('p',[_vm._v(" Press the \"Convert\" button, and within seconds, your JSON data will be transformed into YAML.")]),_c('h2',[_vm._v("Download:")]),_c('p',[_vm._v(" Once the conversion is complete, you can download the YAML file to your computer or device.Start Converting JSON to YAML Today. Save time and effort by using our free JSON to YAML converter. No more manual data formatting or complex conversions. Make your JSON data more human-readable and structured with just a few clicks. Try our converter today and streamline your data conversion process!")]),_c('h3',[_vm._v("Conclusion")]),_c('p',[_vm._v("Converting JSON to YAML doesn't have to be a daunting task. With our user-friendly and free online converter, you can effortlessly switch between these two data formats. Make your JSON data more human-readable and structured, and enhance your projects' efficiency. Start using our JSON to YAML converter today and experience the benefits for yourself. This content provides information about the benefits of converting JSON to YAML, the features of the converter tool, and a step-by-step guide on how to use it. It's designed to be informative and SEO-friendly to attract users looking for a JSON to YAML conversion solution.")])])
}]

export { render, staticRenderFns }