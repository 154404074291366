<template>
    <v-container>
      <v-row justify="center">
        <v-hover v-for="(item, index) in datas" :key="index" v-slot="{ hover }">
          <a 
          style="text-decoration: none;"
          href="https://jsontoyamlonline.com/">
          <v-card
            light
            :class="{ 'ma-3 elevation-8': hover }"
            class="mx-2 mb-6 transparent pa-4"
            height="400"
            max-width="300"
          >
          <v-img 
          :href="item.link"
          class="mt-3" 
          :src="require(`@/assets/${item.img}`)" height="90"></v-img>
            <v-card-title>{{ item.title }}</v-card-title>
            <v-card-text>{{ item.content }}</v-card-text>
          </v-card>
        </a>
        </v-hover>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'TryOur',
    data() {
      return {
        datas: [
          {
            title: 'Epoch timer',
            img: 'clock_logo.svg',
            content: 'Epoch time, also known as Unix time or POSIX time, represents the number of seconds that have elapsed since January 1, 1970, 00:00:00 UTC. It is often used in computing systems to track and reference time.'
          },
          {
            title: 'Json Compare Tools',
            img: 'json_logo.svg',
            content: 'JSON compare tools are essential utilities for developers and data analysts working with JavaScript Object Notation (JSON) data. These tools facilitate the comparison of JSON structures and identify differences between two JSON documents.'
          },
          {
            title: 'Spark Notebook',
            img: 'spark_logo.svg',
            content: 'Spark provides an advanced, unified analytics engine for big data processing, machine learning, graph processing, and real-time data streaming.'
          }
        ]
      }
    }
  }
  </script>
  
  <style scoped>
  /* Add any scoped styles if needed */
  </style>
  