<template>
  <div>
  <v-app-bar
    color="blue accent-4"
    fixed
    height="60"
    max-height="160"
  > 
    <h2 style="color: white">Convert JSON to YAML Online</h2> 
    <v-spacer></v-spacer>
    <h3 
    class="ma-0"><a 
    style="color:white;text-decoration: underline;"
    href="/about">About Us</a><v-icon
    dark
    class="ml-2"
    >
      mdi-web
    </v-icon></h3>  
  </v-app-bar>
  <v-app 
  style="margin-top:60px ;"
  id="vapp">
    
    <v-snackbar
    timeout="3000"
    light
    centered
      v-model="popup"
    >
    {{ pop_msg }}
    </v-snackbar>
    <div>
  </div>
        <v-container fluid> 
          <div :class="{'d-none': saved_tabs.length === 0}">
            <div 
            class="d-flex pa-1"
            style="border: 2px #3883fa solid ;border-radius: 4px;">
            <v-slide-group
            light
            show-arrows
          >
      <v-slide-item
      v-for="(tab,i) in saved_tabs"
      :key="i"
      >
        <v-sheet 
        @click="setTab(tab)"
        class="mx-1 orange lighten-2 pa-1 rounded elevation-2">
          <span class="caption">{{ getTabTitle(tab.data) + '...'}}</span>
          <v-icon right small
            @click="deleteTab(i)"
          >mdi-close</v-icon>
        </v-sheet>
      </v-slide-item>
    </v-slide-group>
            </div>
          
          </div>
          <div 
          class="d-flex justify-center">
      
                  <div 
                  class=" elevation-3 rounded-xxl px-4 d-flex justify-center">
                  <v-btn 
                  class="my-2 ma-3 d-block"
                color="primary"
                @click="jsonToYaml"
                elevation="3"
                >
                &nbsp;&nbsp;YAML&nbsp;&nbsp;
                </v-btn>
                <v-btn
                class="my-2 ma-3 d-block" 
                color="primary"
                @click="jsonToXml"
                elevation="3"
                >
                &nbsp;&nbsp;XML&nbsp;&nbsp;
                </v-btn>
                <v-btn
                dark
                class="my-2 ma-3 d-block" 
                color="red"
                @click="clearInput"
                elevation="3"
                >
                &nbsp;&nbsp;clear&nbsp;&nbsp;
                </v-btn>
                <v-btn
                dark
                class="my-2 ma-3 d-block" 
                color="orange"
                @click="saveTab"
                elevation="3"
                >
                &nbsp;&nbsp;Save&nbsp;&nbsp;
                </v-btn>
              </div>
            
          </div>
            <v-row>
              
              <v-col>
                <JsonEditor 
                height="634px"
                :initVal="init_val"
                :error="input_error_flg"
                ref="jsoneditor"
                @input="getInputField"/>
              </v-col>
              <v-col class="m_output">
                <OutputField
                :feed="jsonfeed"
                ref="outputField"
                :format="select_fromat"
                style="color: rgb(255, 255, 255);"
                />
              </v-col>              
            </v-row>
            <v-row>
              <v-col>
                <TryOur/>
              </v-col>
            </v-row>
        </v-container>  
        <div>
          <About/>
        </div>
      <div>
      <AppFooter />    
    </div>
    
    <v-dialog
               v-model="model"
               width="600"
               light
               >
                <template v-slot:activator="{on,attrs }">
                <div>
                  <v-hover 
                v-slot="{ hover }">
                <v-btn
                color="primary"
                dark
                v-on="on"
                v-bind="attrs"
                x-large
                fixed
                bottom
                right
              >
                <v-icon>mdi-message</v-icon>
                <v-scroll-x-transition>
                <span 
                v-if="hover"
                class="ma-0 ml-2">Feedback</span>
              </v-scroll-x-transition>
              </v-btn>
              </v-hover>
            </div>
            </template>
            <v-card>
              <div class="pt-4 text-h5 d-flex justify-center">
                Give Your FeedBack
              </div>
              <v-divider class="mt-3"></v-divider>
              <div>
              <v-textarea
              outlined
              placeholder="Share your exprience with our tool kindly."
              class="mt-3 px-4"
              rows="15"
              hide-details
              v-model="user_feedback"
              >
              </v-textarea>
            </div>
            <div class="d-flex justify-end">
            <v-btn 
            @click="sendFeedBack"
            color="primary" class="mb-3 mr-3 mt-3">
              Send
            </v-btn>
          </div>
          </v-card>
            </v-dialog>
  </v-app>
</div>
</template>
<script>

import OutputField from '@/components/OutputField.vue';
import AppHeader from '@/components/AppHeader.vue'
import About from '@/components/About.vue'
import AppFooter from '@/components/AppFooter.vue'
import TryOur from '@/components/TryOur.vue'
import jsyaml from 'js-yaml';
import jsxml from 'xml-js';
import JsonEditor from  '@/components/JsonEditor';

  export default {
    name: 'HomeView',
    data(){
      return {
        model : false,
        init_val : {
              "company": {
                "name": "TechCorp",
                "founded": 2000,
                "location": {
                  "city": "Silicon Valley",
                  "country": "USA"
                },
                "departments": [
                  {
                    "name": "Engineering",
                    "head": "John Doe",
                    "employees": [
                      {
                        "id": 101,
                        "name": "Alice Johnson",
                        "position": "Software Engineer",
                        "projects": [
                          {
                            "name": "Project X",
                            "status": "In Progress"
                          },
                          {
                            "name": "Project Y",
                            "status": "Completed"
                          }
                        ]
                      },
                      {
                        "id": 102,
                        "name": "Bob Smith",
                        "position": "Senior Software Engineer",
                        "projects": [
                          {
                            "name": "Project Z",
                            "status": "Planned"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "Marketing",
                    "head": "Jane Williams",
                    "employees": [
                      {
                        "id": 201,
                        "name": "Charlie Brown",
                        "position": "Marketing Specialist"
                      }
                    ]
                  }
                ]
              }
            }
            ,
        jsondata : null,
        jsonfeed : null,
        select_fromat : null,
        input_error_flg : null,
        user_feedback : '',
        popup : false,
        pop_msg : "",
        saved_tabs : []
      }
    },
    components:{
      JsonEditor,   
      OutputField,
      AppHeader,
      About,
      AppFooter,
      TryOur      
    },
    mounted(){
      this.jsondata = this.init_val
      this.jsonToYaml()
      const localTabSaveData = localStorage.getItem('tabs');
      if(localTabSaveData){
        let tabs = JSON.parse(localTabSaveData)
        tabs.forEach((val)=>{
          this.saved_tabs.push({
            data : val
          })
        })
      }else{
        this.saved_tabs.push({
            data : this.init_val
          })
      }
    }
    ,
    methods: {
      deleteTab(index_val){
        const localTabSaveData = localStorage.getItem('tabs');
        let tabs = JSON.parse(localTabSaveData)
        tabs.splice(index_val,1)
        localStorage.setItem('tabs', JSON.stringify(tabs));
        this.saved_tabs.splice(index_val,1)
      },
      saveTab(){
        const localTabSaveData = localStorage.getItem('tabs');
        if(localTabSaveData){
          let tabs = JSON.parse(localTabSaveData)
          tabs.push(this.jsondata)
          localStorage.setItem('tabs', JSON.stringify(tabs));
          this.saved_tabs.push({
            data : this.jsondata
          })
        }else{
          localStorage.setItem('tabs', JSON.stringify([this.jsondata]));
          this.saved_tabs.push({
            data : this.jsondata
          })
        }
        
      },
      setTab(tab_data){
        this.$refs.jsoneditor.setData(tab_data.data)
        this.jsondata = tab_data.data
        this.jsonToYaml()
      },
      getTabTitle(tab_data){
        try{
          let title = JSON.stringify(tab_data).slice(0,30)
          return title

        }catch{
          this.input_error_flg = false
        }
      },
      clearInput(){
        this.$refs.jsoneditor.clear()
        this.jsondata = null
        this.jsonfeed = ''
      },
      getInputField(data){
        this.jsondata = data
      },
      jsonToXml(){
        this.input_error_flg = false
      try{
        this.select_fromat = 'XMl'
      const xmlOutput = jsxml.js2xml(JSON.parse(JSON.stringify(this.jsondata, null, 2)),
      { compact: true, ignoreComment: true, spaces: 4 });
      this.jsonfeed = xmlOutput
      }catch{
        this.input_error_flg = true
      }
      },
      jsonToYaml() { 
        this.input_error_flg = false
      try {
        this.select_fromat = 'YAML'
        const yamlString = jsyaml.dump(JSON.parse(JSON.stringify(this.jsondata, null, 2)  ));
        this.jsonfeed = yamlString;

      } catch (error) {
        this.input_error_flg = true
      }
    },
    sendFeedBack(){
       if(this.user_feedback != ""){
        this.model = false
        fetch("149.248.4.183:8081/send-Feedback",
          {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body  : JSON.stringify({
              feedback : this.user_feedback
            })
          }).then(response => response.json()).then((data)=>{
            if(data.API == 0){
              this.popup = true
              this.user_feedback = ""
              this.pop_msg = "Feedback send sucessfully, Thanks for your Feedback."
            }else{
              this.popup = true
              this.pop_msg = "Unfortunatly Feedback service has down, we will sort this soon."
            }
          })
       }
      }
    }
  }
</script>
<style scoped>
 @media all and (max-width: 1246px){
    .m_controls{
      display: none !important;
    }
    
}
 @media all and (max-width: 800px){
  .m_output {
    flex-basis : inherit !important;
    }
    
    
}

::v-deep .v-textarea textarea {
    color: #000 !important;
}
::v-deep .jsoneditor-menu  {
    border-radius: 5px 5px 0 0 !important;
}
::v-deep  .jsoneditor {
  border-radius: 10px 10px 5px 5px;     
  border: 2px solid #3883fa;  
}
::v-deep  .jsoneditor-poweredBy {
color: #3883fa;
pointer-events: none;
}
::v-deep .v-text-field--filled {
     border-radius: 0 5px 5px 5px;     
     border: 2px solid #3883fa;
     border-top: 0;
}
::v-deep .theme--dark.v-label {
    color: rgba(248, 115, 7, 0.863);
}
::v-deep p {
  text-indent: 50px;
}
#vapp {
  background: rgb(246,245,243);

}

</style>
